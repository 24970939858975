import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography, Link } from "@mui/material";

const RequestPasswordReset = ({ setView }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/request-password-reset`,
        { email }
      );
      setMessage(res.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Žádost o resetování hesla
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          type="email"
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Odeslat žádost
        </Button>
      </form>
      {message && <Typography color="error">{message}</Typography>}
      <Typography>
        <Link href="#" onClick={() => setView("login")}>
          Zpět na přihlášení
        </Link>
      </Typography>
    </Box>
  );
};

export default RequestPasswordReset;
