import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";

const ResetPassword = ({ setView }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Hesla se neshodují.");
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/reset-password`,
        { token, newPassword }
      );
      setMessage(res.data.message);
      setError("");
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Změna hesla
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Nové heslo"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          margin="normal"
          type="password"
        />
        <TextField
          label="Potvrdit nové heslo"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          type="password"
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Změnit heslo
        </Button>
      </form>
      {error && <Typography color="error">{error}</Typography>}
      {message && <Typography color="success">{message}</Typography>}
    </Box>
  );
};

export default ResetPassword;
