import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";

const Register = ({ setView }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [countdown, setCountdown] = useState(null); // Initialize countdown to null

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Hesla nesouhlasí");
      return;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/register`,
        {
          username,
          email,
          password,
        }
      );

      if (res.status === 201) {
        setMessage(res.data.message);
        setCountdown(15); // Set countdown to 15 seconds

        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(countdownInterval);
              setView("welcome");
            }
            return prevCountdown - 1;
          });
        }, 1000);
      } else {
        setMessage("Došlo k neočekávané chybě, zkuste to prosím znovu.");
      }
    } catch (error) {
      // Use the server's error message if it exists, otherwise a generic message
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : "Došlo k neočekávané chybě, zkuste to prosím znovu.";
      setMessage(errorMessage);
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Registrace
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Jméno"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          type="email"
        />
        <TextField
          label="Heslo"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          type="password"
        />
        <TextField
          label="Potvrdit heslo"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          margin="normal"
          type="password"
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Registrovat
        </Button>
      </form>
      {message && (
        <Typography
          sx={{ color: "green", fontWeight: "bold", marginTop: "10px" }}
        >
          {message}
        </Typography>
      )}
      {message &&
        countdown > 0 && ( // Display countdown only if message is shown and countdown is greater than 0
          <Typography sx={{ marginTop: "10px", color: "red" }}>
            Zpět na začátek...{countdown}
          </Typography>
        )}
    </Box>
  );
};

export default Register;
