import React from "react";
import { Drawer, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../mapComponents/assets/logo.png";

const AboutDrawer = ({ open, onClose }) => (
  <Drawer anchor="right" open={open} onClose={onClose}>
   <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, zIndex: 1, top: 0, backgroundColor: "white" }}>
      <Typography variant="h6">O aplikaci Vylez.To App</Typography>
      <IconButton
      edge="end"
      color="inherit"
      onClick={onClose}
      sx={{
        zIndex: 2, // Ensures the icon is above other elements
        position: "relative", // Keeps it inside the layout flow
        pr: 1, // Adjust padding
      }}
    >
        <CloseIcon />
      </IconButton>
    </Box>
    <Box sx={{ p: 2 }}>
    
      <Typography variant="body1" paragraph>
      <strong>Vylez.To App</strong> je jednoduchá appka, která slouží k evidenci zdolaných kopců Českého středohoří.
     Vždy, když na nějaký kopec vylezete, na mapě si ho odškrtněte.
      </Typography>
      <Typography variant="body1" paragraph>
      Na mapě je všech 52 vrcholů s prominencí nad 100m. <strong> To je vždy nový vrchol na každý týden v roce!</strong>
      </Typography>
      <Typography variant="body1" paragraph>
      V hlavní nabídce <strong>"Mé kopce"</strong> se můžete kochat tím, kolik už jste toho pokořili!
      </Typography>
      <Box sx={{ mt: 2 }} />
      <Typography variant="body1" paragraph>
        Co je to <strong>prominence kopce</strong>? Je to zjednodušeně převýšení
        od základny kopce.
      </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
      <img
        src={logo}
        alt="VylezToApp Logo"
        style={{ width: "100%", maxWidth: "300px", height: "auto", borderRadius: 10, objectFit: "contain" }}
      />
    </Box>
  </Drawer>
);

export default AboutDrawer;
