import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box, Typography, Link } from "@mui/material";

const Login = ({ onLoginSuccess, setView }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "Submitting login form with URL:",
      `${process.env.REACT_APP_API_URL}/api/users/login`
    );
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/login`,
        { email, password }
      );
      const { token } = response.data;
      localStorage.setItem("token", token); // Store the token in localStorage
      setMessage("Přihlášení proběholo úspěšně!");
      onLoginSuccess();
      navigate("/"); // redirect to a protected route after login
    } catch (error) {
      console.error("Chyba:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Objevila se chyba během přihlášení. Zkuste to prosím znovu.";
      setMessage(errorMessage);
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Přihlášení
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          type="email"
        />
        <TextField
          label="Heslo"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          type="password"
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Přihlásit se
        </Button>
      </form>
      {message && <Typography color="error">{message}</Typography>}
      <Typography p={1}>
        <Link href="#" onClick={() => setView("requestPasswordReset")}>
          Zapomněl jsem heslo
        </Link>
      </Typography>
    </Box>
  );
};

export default Login;
