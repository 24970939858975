import { useState, useEffect } from "react";
import axios from "axios";

const useMapData = () => {
  const [hills, setHills] = useState([]);
  const [climbedHills, setClimbedHills] = useState(new Set());
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHills = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/hills`
        );
        setHills(response.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching hills:", error);
      }
    };

    const fetchUserClimbedHills = async () => {
      try {
        const token = localStorage.getItem("token"); // Assuming you store the token in localStorage

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/user/climbedHills`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const climbedHillIds = new Set(
          response.data.map((hill) => hill.hillId._id)
        );
        setClimbedHills(climbedHillIds);
      } catch (error) {
        setError(error);
        console.error("Error fetching user's climbed hills:", error);
      }
    };

    fetchHills();
    fetchUserClimbedHills();
  }, []);

  return { hills, climbedHills, setClimbedHills, error };
};

export default useMapData;
