import React, { useState, useContext } from "react";

import { AuthContext } from "../context/AuthContext";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TerrainIcon from "@mui/icons-material/Terrain";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";

import axios from "axios";
import OptionsDrawer from "./menuComponents/OptionsDrawer";
import AboutDrawer from "./menuComponents/AboutDrawer";
import StatsDrawer from "./menuComponents/StatsDrawer";

import logo from "./mapComponents/assets/logo.png";

const BurgerMenu = ({ setModalView, setOpen }) => {
  const { logout } = useContext(AuthContext); // Access logout from AuthContext
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [statsDrawerOpen, setStatsDrawerOpen] = useState(false);
  const [optionsDrawerOpen, setOptionsDrawerOpen] = useState(false);
  const [aboutDrawerOpen, setAboutDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
    if (!open) {
      setDrawerContent([]); // Reset content when drawer is closed
    }
  };

  const toggleStatsDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStatsDrawerOpen(open);
  };

  const toggleOptionsDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOptionsDrawerOpen(open);
  };

  const toggleAboutDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAboutDrawerOpen(open);
  };

  const handleLogout = () => {
    try {
      logout(); // Call the logout function from AuthContext
      setModalView("welcome");
      setOpen(true);
      setDrawerOpen(false);

      // Optionally, reload the page
      window.location.reload();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const fetchClimbedHills = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/user/climbedHills`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Climbed Hills Response:", response.data);

      const climbedHills = response.data;
      setDrawerContent(climbedHills);
      setStatsDrawerOpen(true); // Open the stats drawer
    } catch (error) {
      console.error("Failed to fetch climbed hills", error);
      setDrawerContent([]);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuItemClick = (menuItem) => {
    switch (menuItem) {
      case "stats":
        fetchClimbedHills();
        break;
      case "options":
        setOptionsDrawerOpen(true);
        break;
      case "about":
        setAboutDrawerOpen(true);
        break;
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
    setDrawerOpen(false); // Close the main drawer
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          top: 16,
          right: 16,
          zIndex: 1300, // Ensure it is above other content
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Background color with transparency
          borderRadius: 50,
          width: 56, // Set the width and height to 56px to make it a perfect circle
          height: 56,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "2px 3px 3px #00000099",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{
            padding: 0, // Remove padding
            margin: 0, // Remove margin
            width: 56,
            height: 56,
          }}
        >
          <MenuIcon sx={{ fontSize: 24 }} /> {/* Adjust the font size */}
        </IconButton>
      </Box>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        style={{ maxWidth: 250 }}
      >
        {" "}
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <img
            src={logo}
            alt="VylezToApp Logo"
            style={{ width: "100%", maxWidth: "300px", height: "auto", borderRadius: 10, objectFit: "contain" }}
    
          />
        </Box>
        <Box sx={{ width: 250 }} role="presentation">
          <List>
            <ListItem button onClick={() => handleMenuItemClick("stats")}>
              <ListItemIcon sx={{ color: "#1976d2" }}>
                <TerrainIcon />
              </ListItemIcon>
              <ListItemText primary="Mé kopce" />
            </ListItem>
            <ListItem button onClick={() => handleMenuItemClick("options")}>
              <ListItemIcon sx={{ color: "#1976d2" }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Nastavení" />
            </ListItem>
            <ListItem button onClick={() => handleMenuItemClick("about")}>
              <ListItemIcon sx={{ color: "#1976d2" }}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="O aplikaci" />
            </ListItem>
            <ListItem button onClick={() => handleMenuItemClick("logout")}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Odhlásit se" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <StatsDrawer
        open={statsDrawerOpen}
        onClose={toggleStatsDrawer(false)}
        loading={loading}
        data={drawerContent}
      />
      <OptionsDrawer
        open={optionsDrawerOpen}
        onClose={toggleOptionsDrawer(false)}
      />
      <AboutDrawer open={aboutDrawerOpen} onClose={toggleAboutDrawer(false)} />
    </Box>
  );
};

export default BurgerMenu;
