import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const OptionsDrawer = ({ open, onClose }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handlePasswordChange = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token"); // Retrieve the token from local storage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/user/change-password`,
        {
          oldPassword,
          newPassword,
          confirmNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      setMessage(response.data.message);
      setMessageType("success");

      // Clear the form fields
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");

      // Clear the success message after 5 seconds
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred");
      setMessageType("error");
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve the token from local storage
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/users/user/delete-account`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      setMessage(response.data.message);
      setMessageType("success");

      // Clear the success message after 5 seconds
      setTimeout(() => {
        setMessage("");
        localStorage.removeItem("token"); // Remove the token from local storage
        window.location.reload(); // Reload the page after the message is cleared
      }, 2000);
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred");
      setMessageType("error");
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
    <Box 
      sx={{ 
        display: "flex", 
        justifyContent: "space-between", 
        p: 2, 
        zIndex: 1, 
        top: 0, 
        backgroundColor: "white",
        boxShadow: 3 // Optional: Add shadow for separation
      }}
    >
      <Typography variant="h6">Nastavení</Typography>
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        sx={{
          zIndex: 2, // Ensures the icon is above other elements
          position: "relative", // Keeps it inside the layout flow
          pr: 1, // Adjust padding-right for IconButton
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  
    {/* Add marginTop to the content section to create space between top box and content */}
    <Box sx={{ p: 2, mt: 3 }}> 
      <Typography variant="body1">Změnit heslo</Typography>
      <Box component="form" onSubmit={handlePasswordChange} sx={{ mt: 2 }}>
        <TextField
          label="Stávající heslo"
          type="password"
          fullWidth
          margin="normal"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <TextField
          label="Nové heslo"
          type="password"
          fullWidth
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          label="Potvrďte nové heslo"
          type="password"
          fullWidth
          margin="normal"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Pokračovat
        </Button>
        {message && (
          <Typography
            variant="body2"
            color={messageType === "success" ? "green" : "error"}
            sx={{ mt: 2 }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  
    {/* Delete Account Section */}
    <Box sx={{ p: 2 }}>
      <Typography variant="body1">Smazat účet</Typography>
      {!showDeleteConfirmation && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowDeleteConfirmation(true)}
          sx={{ mt: 2 }}
        >
          Chci smazat účet
        </Button>
      )}
      {showDeleteConfirmation && (
        <>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Opravdu chcete smazat váš účet?
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteAccount}
            >
              Ano
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Ne
            </Button>
          </Box>
        </>
      )}
    </Box>
  </Drawer>
  );
};

export default OptionsDrawer;
