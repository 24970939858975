import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = useState("Verifying...");

  useEffect(() => {
    const token = searchParams.get("token");
    console.log("Verification token:", token);

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/users/verify-email?token=${token}`
        )
        .then((response) => {
          console.log("Verification response:", response.data);
          setVerificationStatus(
            "Email byl úspěšně ověřen. Můžete se vrátit do aplikace."
          );
        })
        .catch((error) => {
          console.error(
            "Verification error:",
            error.response ? error.response.data : error.message
          );
          setVerificationStatus(
            "Ověření emailem selhalo. Zkuste to, prosím, znovu."
          );
        });
    } else {
      console.error("Invalid verification token");
      setVerificationStatus("Invalid verification token. Please try again.");
    }
  }, [searchParams]);

  return (
    <div
      style={{
        position: "relative", // Make the container relative to position the logo absolutely
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: `url('/images/email_verification.webp')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        padding: "20px",
        textAlign: "center",
      }}
    >
      {/* Responsive image element above the H2 */}
      <img
        src="/images/logo.png"
        alt="Logo"
        style={{
          position: "absolute", // Position the logo absolutely
          top: "20px", // Set the distance from the top of the screen
          maxWidth: "100%", // Ensures the image is responsive
          height: "auto",
          padding: "10px", // Maintains the aspect ratio
        }}
      />
      <h2
        style={{
          fontSize: "1.5rem", // Adjust the font size
          padding: "10px", // Add padding around the text
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Add a semi-transparent background
          borderRadius: "10px", // Round the corners
        }}
      >
        {verificationStatus}
      </h2>
    </div>
  );
};

export default VerifyEmail;
