import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";

import "leaflet/dist/leaflet.css";
import "./MapView.css";

import hillIconUrl from "./assets/hill-icon-unchecked.png";
import hillIconClimbedUrl from "./assets/hill-icon-checked.png";

import useMapData from "./useMapData";

const MapView = ({ userData }) => {
  const { hills, error } = useMapData();
  const [climbedHills, setClimbedHills] = useState(new Set()); // Add state for climbedHills
  const [zoomLevel, setZoomLevel] = useState(13); // Default zoom level

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (userData) {
      const climbedHillIds = new Set(userData.map((hill) => hill.hillId._id));
      setClimbedHills(climbedHillIds);
      console.log("climbedHills state has changed", climbedHillIds);
    }
  }, [userData]);

  const handleCheckClick = async (hillId) => {
    const climbDate = new Date().toISOString();

    try {
      const token = localStorage.getItem("token");

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/user/toggleHillClimb`,
        { hillId, climbDate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setClimbedHills((prev) => {
        const newClimbedHills = new Set(prev);
        if (newClimbedHills.has(hillId)) {
          newClimbedHills.delete(hillId);
        } else {
          newClimbedHills.add(hillId);
        }
        return newClimbedHills;
      });
    } catch (error) {
      console.error("Error updating hill climb status:", error);
    }
  };

  // Function to create a custom DivIcon for each hill, adjusted for zoom level
  const createCustomIcon = (hill) => {
    const isClimbed = climbedHills.has(hill._id);
    const iconUrl = isClimbed ? hillIconClimbedUrl : hillIconUrl;
    // Adjust icon size based on zoom level
    const iconSize = zoomLevel <= 11 ? [40, 56] : [80, 110]; // Smaller size at zoom level 13 or below
    const iconAnchor = zoomLevel <= 11 ? [23, 40] : [44, 48]; // Adjust anchor for new size

    const hillNameClass = isClimbed ? "hill-name climbed" : "hill-name";

    const iconHtml = `
      <div class='custom-icon' style='width:${iconSize[0]}px; height:${iconSize[1]}px;
        margin-left:-${iconAnchor[0]}px; margin-top:-${iconAnchor[1]}px;'
      >
        <img src='${iconUrl}' alt='${hill.name}' style='width:100%; height:100%;'/>
        <span class='${hillNameClass}'>${hill.name}</span>
      </div>
    `;
    return new L.DivIcon({
      html: iconHtml,
      className: "MyCustomIcon",
      iconSize: null, // Let CSS handle the sizing
    });
  };

  // Component to listen for zoom level changes
  const ZoomListener = () => {
    useMapEvents({
      zoomend: (e) => {
        setZoomLevel(e.target.getZoom());
      },
    });
    return null;
  };

  return (
    <MapContainer
      center={[50.535, 13.95]}
      zoom={13}
      style={{ height: "100vh", width: "100%" }}
      whenCreated={(mapInstance) => {
        setZoomLevel(mapInstance.getZoom()); // Initialize zoom level state
      }}
    >
      <TileLayer
        url={`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${process.env.REACT_APP_MAPY_CZ_API_KEY}`}
        attribution='Map data &copy; <a href="https://mapy.cz/">Mapy.cz</a>'
        minZoom={11}
        maxZoom={13}
      />
      <ZoomListener />
      {hills.map((hill) => (
        <Marker
          className="hill-marker"
          key={hill._id}
          position={[hill.lat, hill.lng]}
          icon={createCustomIcon(hill)}
        >
          <Popup offset={[0, -30]}>
            <div className="hill-popup">
              <div className="hill-name-popup">{hill.name}</div>
              <div className="hill-height-popup">{hill.height} m</div>
              <button
                className="check-btn"
                onClick={() => handleCheckClick(hill._id)}
              >
                {climbedHills.has(hill._id)
                  ? "Označit jako nevylezené"
                  : "Tohle jsem dal!"}
              </button>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

//jenom testuju

export default MapView;
